
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getTagsList, userTagList, tagUserList, saveBloggerTag } from "@/api/request/tag";

//组件
@Component({
  name: "UserTagsManager",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list1: any[] = [];
  private list2: any[] = [];
  private tab: string = "1";
  private total: number = 0;
  private tagList: any[] = [];
  private tagSelectList: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "",

    //请求数据
    tag_id: 0, // 标签ID
    user_id: 0, // 用户ID
    nick_name: "", // 用户昵称
    user_type: 1, // 用户类型，隐藏传值，必需：1-博主，2-用户
  };

  //创建
  created() {
    //获取标签列表
    this.getTagList();

    //获取列表
    this.getList();
  }

  //处理标签修改
  private handleClick(): void {
    //初始化数据
    this.listQuery = {
      //页面数据
      page: 1,
      page_size: 20,

      //临时数据
      user_id_str: "",

      //请求数据
      tag_id: 0, // 标签ID
      user_id: 0, // 用户ID
      nick_name: "", // 用户昵称
      user_type: 1, // 用户类型，隐藏传值，必需：1-博主，2-用户
    };

    //获取数据
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //用户标签
    if (Number(this.tab) == 1) {
      //清空数据
      this.list1 = [];

      //获取数据
      const { data } = await userTagList(this.listQuery);

      //数据赋值
      this.list1 = data.list;
      this.total = data.total;
    }
    //标签用户
    else {
      //清空数据
      this.list2 = [];

      //获取数据
      const { data } = await tagUserList(this.listQuery);

      //数据赋值
      this.list2 = data.list;
      this.total = data.total;
    }

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取列表
  private async getTagList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagsList({ page: 1, page_size: 1000, name: "" });

    //数据赋值
    this.tagList = data.list;

    //数据赋值
    this.tagList = [];
    this.tagSelectList = [];
    for (var i: number = 0; i < data.list.length; i++) {
      this.tagList.push(data.list[i]);
      this.tagSelectList.push(data.list[i]);
    }
    this.tagSelectList.unshift({ id: 0, name: "全部标签" });
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form.tags = [];
    this.form.blogger_id = row.id;
    this.form.blogger_name = row.nick_name;
    for (var i: number = 0; i < row.tag_list.length; i++) {
      this.form.tags.push({
        //请求数据
        id: row.tag_list[i].tag_id,
        name: row.tag_list[i].tag_name,
        type: row.tag_list[i].tag_type,
        value: row.tag_list[i].tag_type == 1 ? (row.tag_list[i].tag_val == 0 ? false : true) : String(row.tag_list[i].tag_val),
      });
    }

    //显示对话框
    this.dialogVisible = true;
  }

  //获取标签状态
  private getTagStatus(row: any, item: any): string {
    //数据赋值
    var tag_type: number = 0;
    var tag_value: number = 0;
    for (var i: number = 0; i < row.tag_list.length; i++) {
      if (item.id == row.tag_list[i].tag_id) {
        tag_value = row.tag_list[i].tag_val;
        tag_type = row.tag_list[i].tag_type;
        break;
      }
    }

    //反回结果
    return tag_type == 1 ? (tag_value == 0 ? "否" : "是") : String(tag_value);
  }

  //获取选择内容
  private getinPutList(tags: Array<any>): Array<any> {
    //数据赋值
    var list: Array<any> = [];
    for (var i: number = 0; i < tags.length; i++) {
      if (tags[i].type == 2) {
        list.push({ index: i, tag: tags[i] });
      }
    }
    return list;
  }

  //获取选择内容
  private getCheckBoxList(tags: Array<any>): Array<any> {
    //数据赋值
    var list: Array<any> = [];
    for (var i: number = 0; i < tags.length; i++) {
      if (tags[i].type == 1) {
        list.push({ index: i, tag: tags[i] });
      }
    }
    return list;
  }

  //上传列表
  //---------------------------------- 创建/编辑 ----------------------------------
  //定义变量
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //列表请求参数
  private form: any = {
    tags: [],
    blogger_id: 0,
    blogger_name: "",
  };

  //处理保存
  private async btneSave() {
    //显示等待
    this.dialogLoading = true;

    //数据赋值
    for (var i: number = 0; i < this.form.tags.length; i++) {
      if (this.form.tags[i].type == 1) {
        this.form.tags[i].value = this.form.tags[i].value ? 1 : 0;
      } else if (this.form.tags[i].type == 2) {
        this.form.tags[i].value = Number(this.form.tags[i].value);
      }
    }

    //编辑礼包
    const res = await saveBloggerTag(this.form);

    //显示提示
    this.$message.success("编辑成功");

    //隐藏界面
    this.dialogVisible = false;
    this.dialogLoading = false;

    //获取数据
    this.getList();
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }
}
